import { Button, styled } from "@mui/material"
import theme from "../../theme"

export const ClusterContainer = styled("div")`
  display: flex;
  position: absolute;
  background: ${theme.palette.secondary.main};
  border-radius: 8px;
  margin-top: -85px;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
  border: 1px solid ${theme.palette.neutrals[3]};

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    display: none;
  }
`

export const ClusterButtonSingle = styled(Button)`
  color: ${theme.palette.primary.contrastText};
  text-transform: capitalize;
  border: 1px solid ${theme.palette.neutrals[3]};
  margin-bottom: 5px;
  margin-top: 5px;
`

export const ClusterButton = styled(Button)`
  color: ${theme.palette.primary.contrastText};
  text-transform: capitalize;
  border: 1px solid ${theme.palette.neutrals[3]};
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
`

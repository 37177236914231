import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api"
import MapIcon from "../../assets/Option-5.svg"
import { useEffect, useRef, useState } from "react"
import theme from "../../theme"

interface IMapProps {
  locationDetails: LocationDetail[]
  smoothScrollToId: (id: string, offset?: number, duration?: number) => void
  active: string
  city: string
}

interface LocationDetail {
  centerId: string
  centerName: string
  lat: number
  lng: number
  clusterName: string | null
}

const center = {
  lat: 12.9466,
  lng: 77.6364,
}

const GoogleMapComp = ({
  locationDetails,
  smoothScrollToId,
  active,
  city,
}: IMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
  })

  const mapRef = useRef<google.maps.Map | null>(null)
  const [mapReady, setMapReady] = useState(false)
  const prevActiveRef = useRef(active)
  const prevCityRef = useRef(city)

  // Validate location coordinates
  const isValidLocation = (loc: LocationDetail) => {
    return (
      loc &&
      typeof loc.lat === "number" &&
      typeof loc.lng === "number" &&
      !isNaN(loc.lat) &&
      !isNaN(loc.lng)
    )
  }

  // Get filtered locations with validation
  const getFilteredLocations = () => {
    let filtered = locationDetails || []

    if (active === "All") {
      filtered = filtered.filter((loc) =>
        city === "Mumbai"
          ? loc.clusterName === "Mumbai"
          : loc.clusterName !== "Mumbai",
      )
    } else {
      filtered = filtered.filter((loc) => loc.clusterName === active)
    }

    return filtered.filter(isValidLocation)
  }

  // Fit map to bounds
  const fitMapToMarkers = () => {
    if (!mapRef.current || !mapReady) return

    const filteredLocations = getFilteredLocations()
    if (filteredLocations.length === 0) return

    const bounds = new google.maps.LatLngBounds()
    filteredLocations.forEach((loc) => {
      bounds.extend(new google.maps.LatLng(loc.lat, loc.lng))
    })

    // Add padding to bounds
    const padding = window.innerWidth < 600 ? 50 : 100
    mapRef.current.fitBounds(bounds, padding)
  }

  // Handle map initialization
  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map
    setMapReady(true)
    fitMapToMarkers()
  }

  // Handle changes that should trigger bounds update
  useEffect(() => {
    if (
      mapReady &&
      (active !== prevActiveRef.current || city !== prevCityRef.current)
    ) {
      fitMapToMarkers()
      prevActiveRef.current = active
      prevCityRef.current = city
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, city, mapReady])

  // Handle initial load and location details changes
  useEffect(() => {
    if (mapReady && locationDetails?.length) {
      fitMapToMarkers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationDetails, mapReady])

  if (!isLoaded) return null

  return (
    <GoogleMap
      onLoad={handleMapLoad}
      onUnmount={() => {
        mapRef.current = null
        setMapReady(false)
      }}
      center={center}
      zoom={12}
      options={{
        disableDefaultUI: true,
        styles: [
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [{ visibility: "on" }],
          },
        ],
        gestureHandling: "greedy", // Better mobile handling
      }}
      mapContainerStyle={{
        height: window.innerWidth < 900 ? 300 : 400,
        minWidth: window.innerWidth < 900 ? 300 : 600,
        margin: window.innerWidth < 600 ? "0 auto" : 0,
        maxWidth: window.innerWidth < 600 ? 320 : "auto",
        border: `2px solid ${theme.palette.neutrals[5]}`,
        borderRadius: 8,
      }}
    >
      {mapReady &&
        getFilteredLocations().map((location) => (
          <MarkerF
            key={location.centerId}
            position={{ lat: location.lat, lng: location.lng }}
            title={location.centerName}
            icon={{
              url: MapIcon,
              scaledSize: new google.maps.Size(32, 32),
            }}
            onClick={() =>
              smoothScrollToId(
                location.centerId,
                window.innerWidth < 600 ? 80 : 100,
                2000,
              )
            }
          />
        ))}
    </GoogleMap>
  )
}
export default GoogleMapComp

import StaticPageHero from "../../components/StaticPageHero/StaticPageHero"
import { ParentContainer, Container } from "./styles"

const PrivacyPolicy = () => {
  return (
    <ParentContainer>
      <StaticPageHero text="Privacy Policy" />
      <Container>
        <p>
          This Privacy Policy (“Policy”) applies to{" "}
          <strong>
            <u>
              <span>
                Tusker Workspace Pvt. Ltd (“TUSKER”), a company incorporated
                under the Indian Companies Act 2013
              </span>
            </u>
          </strong>{" "}
          having its registered office at{" "}
          <strong>
            No. 148, BBMP No. 170/148, 5th Main Road, Sector – 6, HSR Layout,
            Bangalore – 560102
          </strong>{" "}
          and{" "}
          <strong>
            <u>
              <span>
                all its group companies/ LLPs/ subsidiaries/ affiliates
                including BHIVE Zenith LLP (“ZENITH”), operating under the brand
                name/s of BHIVE Workspace/ Garuda BHIVE Workspace/ BHIVE
                Premium/ BHIVE Platinum ( hereinafter collectively referred to
                as “BHIVE”/ “We”/ “Us”)
              </span>
            </u>
          </strong>
        </p>

        <p>
          BHIVE recognizes the importance of maintaining its member’s (“Your/
          You”) privacy. We value Your privacy and appreciate Your trust in Us.
          This Policy describes how We treat Your/ any user information We
          collect on{" "}
          <a href="www.bhiveworkspace.com"> www.bhiveworkspace.com</a> and other
          offline sources. This Privacy Policy applies to current and former
          visitors to BHIVE’s website and to BHIVE’s online customers. By
          visiting and/or using the website, You agree to be bound by this
          Privacy Policy.
        </p>

        <p>
          <strong>
            <u>INFORMATION WE COLLECT</u>
          </strong>
        </p>
        <p>
          Contact information. We might collect your Name, Email, Phone, Address
        </p>

        <p>
          <strong>
            <u>WE COLLECT INFORMATION IN DIFFERENT WAYS.</u>
          </strong>
        </p>
        <p>
          We collect information directly from You when You contact Us or if You
          post a comment on BHIVE’s website or ask Us a question through phone
          or email.
        </p>

        <p>
          <strong>
            <u>USE OF YOUR PERSONAL INFORMATION</u>
          </strong>
        </p>

        <p>We use information:</p>

        <ol>
          <li>
            to respond to Your requests or questions and might use Your
            information to confirm Your registration for an event or contest.
          </li>
          <li>
            {" "}
            improve BHIVE’s products and services. We might use Your information
            to customize your experience with Us. This could include displaying
            content based upon Your preferences.
          </li>
          <li>
            to look at site trends and customer interests. BHIVE may use Your
            information to make its website and products better. We may combine
            information We get from You with information about You We get from
            third party vendors, including Google, use first-party cookies (such
            as the Google Analytics cookie) and third-party cookies (such as the
            DoubleClick cookie) together to inform, optimize and deliver better
            experience based on someone’s past visits to BHIVE’s website.
          </li>
          <li>
            for security purposes. BHIVE may use information to protect itself
            including its website, andMember/s. We use information as otherwise
            permitted by law.
          </li>
        </ol>

        <p>
          <strong>
            <u>DISCLAIMER</u>
          </strong>
        </p>
        <p>
          The materials provided by BHIVE are given “as is”. BHIVE makes no
          guarantees, communicated or suggested, and thus renounces and
          nullifies every single other warranties, including without impediment,
          inferred guarantees or states of merchantability, fitness for a
          specific reason, or non- encroachment of licensed property or other
          infringement of rights. Further, BHIVE does not warrant or make any
          representations concerning the precision, likely results, or
          unwavering quality of the utilization of the materials on its internet
          site or generally identifying with such materials or on any
          destinations connected to this website.
        </p>

        <p>
          <strong>
            <u>CONSTRAINTS</u>
          </strong>
        </p>
        <p>
          In no occasion should BHIVE or its suppliers subject any harm
          (counting, without constraint, harms for loss of information or
          benefit, or because of business interference,) emerging out of the
          utilization or powerlessness to utilize the materials on BHIVE’s
          Internet webpage, regardless of the possibility that BHIVE d or a
          BHIVE approved agent has been told orally or in written of the
          likelihood of such harm. Since a few purviews don’t permit constraints
          on inferred guarantees, or impediments of obligation for weighty or
          coincidental harms, these confinements may not make a difference to
          You.
        </p>

        <p>
          <strong>
            <u>AMENDMENTS</u>
          </strong>
        </p>
        <p>
          The materials showing up on BHIVE’s site could include typographical
          or photographic errors. BHIVE does not warrant that any of the
          materials on its site are accurate, complete, or current. BHIVE may
          roll out improvements/ amendments to the materials contained on its
          site, without notification, as and when any such amendments are made.
        </p>

        <p>
          <strong>
            <u>LINKS</u>
          </strong>
        </p>
        <p>
          BHIVE is not in charge of the substance of any connected webpage. The
          incorporation of any connection does not infer support by BHIVE of the
          site. Utilization of any such connected site is at the user’s own
          risk.
        </p>

        <p>
          <strong>
            <u>GOVERNING LAW AND JURISDICTION</u>
          </strong>
        </p>
        <p>
          The Policy and any dispute as may arise out of it shall be governed by
          the laws of India and subjected to the courts in Bengaluru, Karnataka,
          India. If You choose to visit the website, Your visit and any dispute
          over privacy is subject to this Policy and the website’s terms of use.
        </p>

        <p>
          You can opt out of receiving BHIVE’s marketing emails. To stop
          receiving such promotional emails, please email at{" "}
          <a href="mailto:sales@bhiveworkspace.com">sales@bhiveworkspace.com</a>
          It may take tentatively 10 (ten) working days to process Your request.
          Even if You opt out of receiving marketing messages, BHIVE will be
          communicating transactional messages through email and SMS about Your
          purchases.
        </p>

        <p>
          <strong>
            <u>GRIEVANCE OFFICER</u>
          </strong>
        </p>
        <p>
          In accordance with the Information Technology Act 2000 and rules made
          there under, the name and contact details of the Grievance Officer by
          BHIVE are provided below:
        </p>

        <p>Mr. Manohar,</p>
        <p>BHIVE Workspace (Office Space & Coworking),</p>
        <p>L-148, 5th Main Rd, Sector 6, HSR Layout,</p>
        <p>Bengaluru, Karnataka 560102</p>
        <p>
          Email:{" "}
          <a href="mailto:it@bhiveworkspace.com">it@bhiveworkspace.com</a>
        </p>
        <p>
          Phone: <a href="tel:+919538677774">+91 95386 77774</a>
        </p>
        <p>
          If You have any questions about this Policy or other privacy concerns,
          You can also email Us at{" "}
          <a href="mailto:it@bhiveworkspace.com">it@bhiveworkspace.com</a>.
        </p>

        <p>
          <strong>
            <u>UPDATES TO THIS POLICY</u>
          </strong>
        </p>
        <p>
          This Policy was last updated on 9th March 2016, which may change from
          time-to-time. We will notify You of any material changes to this
          Policy as required by law and a copy of the same shall be upadted on
          the website as well.. You are kindly requested to keep checking the
          website at frequent intervals for the same.
        </p>
      </Container>
    </ParentContainer>
  )
}

export default PrivacyPolicy

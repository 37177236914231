import { Typography, styled } from "@mui/material"
import theme from "../../theme"
import { Button } from "../../components/Button"

export const Wrapper = styled("div")`
  width: 100%;
  max-width: 584px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 48px;
  gap: 24px;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    padding-block: 20px;
    margin: 20px 16px;
  }
`

export const CenterDetailsCard = styled("div")`
  width: 100%;
  max-width: 584px;
  min-width: 328px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    background: ${theme.palette.background.paper};
    border: 1px solid ${theme.palette.neutrals[4]};
    border-radius: 8px;
    padding: 16px 12px;
    gap: 14px;
    height: 295px;
  }
`

export const CenterHeading = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  color: ${theme.palette.primary.contrastText};
  align-self: center;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    font-size: 18px;
  }
`

export const CenterImage = styled("img")`
  width: 100%;
  min-width: 304px;
  height: 328px;
  border-radius: 10px;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    height: 220px;
    border-radius: 6px;
  }
`

export const CenterImagePlaceholder = styled("div")`
  width: 100%;
  height: 328px;
  border-radius: 10px;
  background: ${theme.palette.neutrals[4]};

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    height: 220px;
    border-radius: 6px;
  }
`

export const CustomButton = styled(Button)`
  width: 70%;
  border-radius: 8px;
  text-transform: capitalize;
  &.Mui-disabled {
    border-radius: 8px;
    width: 70%;
    opacity: 0.4;
    background: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
  }
  @media (min-width: ${theme.breakpoints.values["sm"]}px) {
    width: 50%;
    &.Mui-disabled {
      width: 50%;
    }
  }
`
export const NormalAnchor = styled("a")`
  text-decoration: underline;
  color: inherit;
`
export const BackButton = styled(Button)`
  width: 30%;
  @media (min-width: ${theme.breakpoints.values["sm"]}px) {
    width: 50%;
  }
`

export const HeadingContainer = styled("div")`
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  color: ${theme.palette.neutrals[2]};
`

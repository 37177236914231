export class User {
  accessToken = ""
  refreshToken = ""
  userId = ""
  mobile = ""
  firstName = ""
  LastName = ""
  gstDetails = {}

  constructor(user?: any) {
    if (user) {
      this.accessToken = user?.access_token
      this.refreshToken = user?.refresh_token
      this.userId = user?.user?.id
      this.mobile = user?.user?.mobile
      this.firstName = user?.user?.first_name
      this.LastName = user?.user?.last_name
      const activeGstDetail = user?.user?.gst_details?.find(
        (detail: { is_active: boolean }) => detail.is_active === true,
      )
      this.gstDetails = {
        gstin: activeGstDetail?.gstin,
        pincode: activeGstDetail?.pincode,
        companyAddress: activeGstDetail?.company_address,
        companyName: activeGstDetail?.company_name,
      }
    }
  }
}

export class UserContact {
  userId = ""
  mobile = ""
  nickname = ""

  constructor(contacts?: any) {
    if (contacts) {
      this.userId = contacts?.id
      this.mobile = contacts?.mobile
      this.nickname = contacts?.nickname
    }
  }
}

import { CircularProgress, Stack } from "@mui/material"
import {
  CenterDetailsCard,
  CenterHeading,
  CenterImage,
  CenterImagePlaceholder,
  HeadingContainer,
  NormalAnchor,
  Wrapper,
} from "./styles"
import { useGetCenterByIdQuery } from "../../apis/centersApi"
import { useParams, useSearchParams } from "react-router-dom"
import Carousel from "react-material-ui-carousel"
import { resetBookingState, selectBooking } from "../../slices/bookingSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined"
import Stepper from "../../components/Stepper/Stepper"
import StepperContent from "./StepperContent"
import { useEffect } from "react"
import { URLParams } from "../../constants/enums"
import { LocationIconContainer, TruncatedText } from "./StepperContent/styles"
import { staticRelativeImageHandler } from "../../utils/imageHandler"

const Booking = () => {
  const dispatch = useAppDispatch()
  const { centerId = "" } = useParams()
  const { steps } = useAppSelector(selectBooking)
  const [searchParams] = useSearchParams()
  const activeState = searchParams.get(URLParams.ACTIVE_STATE)

  useEffect(() => {
    return () => {
      dispatch(resetBookingState())
    }
  }, [dispatch])

  const {
    data: centerDetails,
    isFetching,
    isLoading,
  } = useGetCenterByIdQuery({ centerId })

  return (
    <Wrapper>
      {isLoading && isFetching ? (
        <CircularProgress size={80} sx={{ alignSelf: "center" }} />
      ) : (
        <>
          <CenterDetailsCard>
            <Carousel animation="slide" indicators={false} autoPlay={false}>
              {centerDetails?.centerImages &&
              centerDetails.centerImages?.length !== 0 ? (
                centerDetails.centerImages.map((src, index) => (
                  <Stack key={index} gap={1}>
                    <CenterImage
                      src={staticRelativeImageHandler(src)}
                      alt={`Uploaded ${index}`}
                    />
                  </Stack>
                ))
              ) : (
                <CenterImagePlaceholder />
              )}
            </Carousel>
            <CenterHeading>{centerDetails?.centerName}</CenterHeading>
            <HeadingContainer>
              <LocationIconContainer style={{ marginBottom: -2 }}>
                <PinDropOutlinedIcon sx={{ fontSize: 14 }} />
              </LocationIconContainer>

              <TruncatedText>
                <NormalAnchor
                  href={centerDetails?.googleMapURL ?? undefined}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {centerDetails?.centerAddress}
                </NormalAnchor>
              </TruncatedText>
            </HeadingContainer>
          </CenterDetailsCard>
          <Stepper
            steps={steps}
            activeStep={Number(activeState)}
            stepperContent={
              <StepperContent
                daypassPrice={centerDetails?.daypassPrice}
                discountsPercentage={centerDetails?.discountsPercentage}
              />
            }
          />
        </>
      )}
    </Wrapper>
  )
}

export default Booking

import { createApi, FetchBaseQueryError } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../utils/baseQuery"
import { CENTERS_MODULE } from "../constants/modules"
import {
  CENTERS_API,
  CENTER_BY_ID_API,
  GST_USER_ME_API,
} from "../constants/apis"
import { Center } from "../models/centers.model"
import { computeRoute } from "../utils/compute-route"
import { setGstDetails } from "../slices/userSlice"
import { showToast } from "../slices/appSlice"

interface GetCenterByIdParams {
  centerId: string
}
interface CenterData {
  id: string
  name: string
  address: string
  google_maps_url: string
  day_pass_price: string
  images: string
  day_pass_discounts_percentage: {
    10: {
      value: number
    }
  }
  google_maps_thumbnail_url: string
}

interface GetCentersAPIResponse {
  data: CenterData[]
}
interface GetCenterByIdAPIResponse {
  data: CenterData
}

interface ResponseData {
  data: {
    gst_details: {
      gstin: string
      company_address: string
      pincode: string
      company_name: string
      is_active: boolean
    }[]
  }
}

export const centersApi = createApi({
  baseQuery: baseQuery,
  tagTypes: [CENTERS_MODULE],
  reducerPath: `/api`,
  endpoints: (builder) => ({
    getCenters: builder.query<Center[], any>({
      query: () => ({
        url: `${CENTERS_API}`,
        method: "GET",
        params: {
          limit: 1000,
          is_day_pass_enabled: true,
        },
      }),
      transformResponse: (response: GetCentersAPIResponse) =>
        response?.data?.map((data) => ({
          ...new Center(data),
        })),
    }),
    getCenterById: builder.query<Center, GetCenterByIdParams>({
      query: ({ centerId }) => ({
        url: computeRoute(CENTER_BY_ID_API, { centerId }),
        method: "GET",
      }),
      transformResponse: (response: GetCenterByIdAPIResponse) => ({
        ...new Center(response?.data),
      }),
    }),
    updateUserGstInfo: builder.mutation({
      queryFn: async (gstInfo, { dispatch }, _, baseQuery) => {
        try {
          const response = await baseQuery({
            url: GST_USER_ME_API,
            method: "POST",
            body: {
              gstin: gstInfo.gstin,
              company_name: gstInfo.companyName,
              company_address: gstInfo.companyAddress,
              pincode: gstInfo.pincode,
            },
          })
          if (response.error) {
            dispatch(
              showToast({
                toastType: "error",
                msg: "Something went wrong",
              }),
            )
            return { error: response.error as FetchBaseQueryError } // Return the error
          }

          const activeGstDetails = (
            response.data as ResponseData
          ).data.gst_details.find(
            (detail: { is_active: boolean }) => detail.is_active === true,
          )
          const updatedUserGstDetails = {
            gstin: activeGstDetails?.gstin,
            companyAddress: activeGstDetails?.company_address,
            pincode: activeGstDetails?.pincode,
            companyName: activeGstDetails?.company_name,
          }
          dispatch(setGstDetails({ gstDetails: updatedUserGstDetails }))
          dispatch(
            showToast({
              toastType: "success",
              msg: "Successfully Updated",
            }),
          )
          return { data: updatedUserGstDetails }
        } catch (error) {
          console.error("Error in queryFn:", error)
          return { error: error as FetchBaseQueryError } // Return custom error
        }
      },
    }),
  }),
})

export const {
  useGetCentersQuery,
  useGetCenterByIdQuery,
  useUpdateUserGstInfoMutation,
} = centersApi

import { styled } from "@mui/material"
import theme from "../../theme"

export const ParentContainer = styled("div")`
  width: 100%;
  max-width: 1200px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
  }
`

export const Container = styled("div")`
  color: ${theme.palette.neutrals[6]};
  font-weight: 500;
  line-height: 24px;
  margin-top: 30px;
  a {
    color: ${theme.palette.primary.dark};
  }
  span {
    color: ${theme.palette.error.main};
  }
`

import React from "react"
import FormControl from "@mui/material/FormControl"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import { FormHelperText } from "@mui/material"
import { CustomOutlinedInput } from "./styles"

type PhoneInputProps = {
  value: string
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleClearMobileNumber?: () => void
  error?: boolean
  handlePressEnter: () => void
  disabled?: boolean
}

const PhoneInput = ({
  value,
  onChangeHandler,
  handleClearMobileNumber,
  error,
  handlePressEnter,
  disabled = false,
}: PhoneInputProps) => {
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !evt.key.match(/[0-9]/) &&
      !(evt.key === "Backspace" || evt.key === "Delete") &&
      !((evt.ctrlKey || evt.metaKey) && evt.key === "v")
    ) {
      evt.preventDefault()
    }
    if (["e", "E", "+", "-"].includes(evt.key)) {
      evt.preventDefault()
    }
    if (evt.key === "Enter") {
      handlePressEnter()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData
    const pastedData = clipboardData?.getData("Text")
    const numericPastedData = pastedData?.replace(/[^0-9]/g, "")
    event.preventDefault()
    if (numericPastedData) {
      const newValue = value + numericPastedData
      onChangeHandler({
        target: { value: newValue },
      } as React.ChangeEvent<HTMLInputElement>)
    }
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <CustomOutlinedInput
        sx={{ height: "44px" }}
        disabled={disabled}
        type="text"
        placeholder="9999 9999 99"
        autoComplete="off"
        value={value}
        onPaste={handlePaste}
        onChange={onChangeHandler}
        startAdornment={<InputAdornment position="start">+91</InputAdornment>}
        endAdornment={
          handleClearMobileNumber ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClearMobileNumber} edge="end">
                <CancelOutlinedIcon color="error" />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        onKeyDown={handleKeyDown}
        error={error}
      />
      {error && <FormHelperText error>Phone number is invalid</FormHelperText>}
    </FormControl>
  )
}

export default PhoneInput

import { Button, styled } from "@mui/material"
import theme from "../../theme"

export const ClusterContainer = styled("div")`
  display: none;
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    display: block;
    height: 55px;
    background: transparent;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
  }

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    display: block;
    height: 55px;
    background: transparent;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
  }

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    display: block;
    height: 55px;
    background: transparent;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
  }
`

export const ClusterButtonSingle = styled(Button)`
  color: ${theme.palette.primary.contrastText};
  text-transform: capitalize;
  border: 1px solid ${theme.palette.neutrals[3]};
  margin-bottom: 10px;
`

export const ClusterButton = styled(Button)`
  color: ${theme.palette.primary.contrastText};
  text-transform: capitalize;
  border: 1px solid ${theme.palette.neutrals[3]};
  margin-left: 10px;
  margin-bottom: 10px;
`

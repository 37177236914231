import { Dialog, Stack } from "@mui/material"
import {
  OTPModalContent,
  OTPModalTitle,
  OTPModalSubtite,
  CloseIconButton,
  InputWrapper,
  ButtonWrapper,
  BackButton,
} from "./styles"
import OtpInput from "react-otp-input"
import OTPMobileIcon from "../../assets/OTPMobileIcon.svg"
import { useState } from "react"
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query"
import { UserLoginParams } from "../../apis/userApi"
import CloseIcon from "@mui/icons-material/Close"
import { User } from "../../models/users.model"
import { ConfirmButton } from "../../containers/Booking/StepperContent/styles"

type OTPModalProps = {
  open: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  mobileNumber: string
  count?: number
  onConfirm: MutationTrigger<
    MutationDefinition<
      UserLoginParams,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        object,
        FetchBaseQueryMeta
      >,
      "centers",
      User,
      "/api"
    >
  >
  resendOTP: () => void
  disabled?: boolean
}

const OTPModal = ({
  open,
  onClose,
  onConfirm,
  mobileNumber,
  resendOTP,
  count,
  disabled,
}: OTPModalProps) => {
  const [otp, setOtp] = useState("")

  const handleConfirm = () => {
    onConfirm({ mobileNumber, OTP: otp })
    onClose(false)
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="otp-dialog-title"
      aria-describedby="otp-dialog-description"
      onClose={onClose}
      PaperProps={{ style: { borderRadius: "12px" } }}
    >
      <CloseIconButton onClick={() => onClose(false)}>
        <CloseIcon />
      </CloseIconButton>
      <OTPModalContent>
        <img src={OTPMobileIcon} height="48px" alt="" />
        <OTPModalTitle>
          Please check the OTP received on your mobile
        </OTPModalTitle>
        <OTPModalSubtite>
          We've sent the code to <span>{mobileNumber}</span>
        </OTPModalSubtite>
        <Stack
          direction="column"
          mt="20px"
          style={{
            width: "100%",
          }}
        >
          <InputWrapper>
            <OtpInput
              value={otp}
              onChange={setOtp}
              shouldAutoFocus
              numInputs={4}
              inputType="number"
              renderInput={(props) => <input {...props} />}
            />
          </InputWrapper>
          <OTPModalSubtite sx={{ textAlign: "left" }}>
            Didn’t receive a code?{" "}
            <span
              className={disabled ? "disabled" : "resend"}
              onClick={() => {
                disabled ? null : resendOTP()
              }}
            >
              {count ? count : ""} Resend OTP
            </span>
          </OTPModalSubtite>
        </Stack>
        <ButtonWrapper>
          <BackButton variant="outlined" onClick={() => onClose(false)}>
            Cancel
          </BackButton>
          <ConfirmButton
            sx={{ width: "70%" }}
            variant="contained"
            onClick={handleConfirm}
          >
            Verify
          </ConfirmButton>
        </ButtonWrapper>
      </OTPModalContent>
    </Dialog>
  )
}

export default OTPModal

import { FormHelperText, styled } from "@mui/material"
import theme from "../../theme"

export const TitleContainer = styled("div")``

export const TitleText = styled("div")`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`
export const GstModalContainer = styled("div")`
  padding: 36px;
  min-width: 600px;

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    min-width: 600px;
  }

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    min-width: 400px;
  }

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    min-width: 300px;
    padding: 16px;
  }

  @media (max-width: 370px) {
    min-width: 250px;
    padding: 16px;
  }
`

export const FormContainer = styled("div")`
  margin-top: 16px;
`

export const RedText = styled("span")`
  color: ${theme.palette.error.main};
`

export const StyledFormHelperText = styled(FormHelperText)`
  margin: 0px;
`

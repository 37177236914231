import { centersApi } from "./centersApi"
import {
  USER_LOGOUT_API,
  USERS_CONTACTS_BY_ID_API,
  USERS_LOGIN_API,
  USERS_REQUEST_OTP_API,
} from "../constants/apis"
import { User, UserContact } from "../models/users.model"
import { computeRoute } from "../utils/compute-route"
import {
  flushUserDataFromLocalStorage,
  setUserDataToLocalStorage,
} from "../utils/storageUtils"
import { showToast } from "../slices/appSlice"

export interface GetOTPParams {
  mobileNumber: string
}

export interface UserLoginParams {
  mobileNumber: string
  OTP: string
}

interface GetUserContactsByIdParams {
  userId: string
}
interface GetUserContactsByIdAPIResponse {
  data: {
    user_id: string
    mobile: string
    nickname: string
  }[]
}

export const userApi = centersApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserByOid: builder.query({
      query: (oid: string) => `/users/oid/${oid}`,
      keepUnusedDataFor: 300,
    }),
    getOTP: builder.mutation<any, GetOTPParams>({
      query: ({ mobileNumber }) => ({
        url: USERS_REQUEST_OTP_API,
        method: "POST",
        body: {
          mobile: mobileNumber,
        },
      }),
    }),
    userLogin: builder.mutation<User, UserLoginParams>({
      query: ({ mobileNumber, OTP }) => ({
        url: USERS_LOGIN_API,
        method: "POST",
        body: {
          mobile: mobileNumber,
          otp: OTP,
        },
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const response: any = await queryFulfilled
          const {
            user,
            expires_in: tokenExpiry,
            access_token: authToken,
            refresh_token: refreshToken,
          } = response.data.data
          const updatedUser = {
            ...new User(response?.data?.data),
          }
          setUserDataToLocalStorage(
            dispatch,
            user,
            authToken,
            refreshToken,
            updatedUser,
            tokenExpiry,
          )
        } catch (e) {
          console.error(e)
        }
      },
    }),
    logoutUser: builder.mutation({
      query: () => {
        return {
          url: USER_LOGOUT_API,
          method: "POST",
        }
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            showToast({
              toastType: "success",
              msg: "Logged out successfully",
            }),
          )
          flushUserDataFromLocalStorage(dispatch)
        } catch (err) {
          console.error(err)
        }
      },
    }),
    getUserContactsById: builder.query<
      UserContact[],
      GetUserContactsByIdParams
    >({
      query: ({ userId }) => ({
        url: computeRoute(USERS_CONTACTS_BY_ID_API, { userId }),
        method: "GET",
      }),
      transformResponse: (response: GetUserContactsByIdAPIResponse) =>
        response?.data?.map((data) => ({
          ...new UserContact(data),
        })),
    }),
  }),
})

export const {
  useGetOTPMutation,
  useUserLoginMutation,
  useGetUserContactsByIdQuery,
  useLogoutUserMutation,
} = userApi

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material"
import theme from "../../theme"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectUser, setUserCity } from "../../slices/userSlice"
import Cookies from "js-cookie"
import isEmpty from "lodash/isEmpty"

interface IModalProps {
  open: boolean
  setShowCitySelectionModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CityModal = ({ open, setShowCitySelectionModal }: IModalProps) => {
  const { userCity } = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setUserCity({ city: event.target.value }))
    Cookies.set("city", event.target.value, { expires: 7 })
  }

  const handleConfirm = () => {
    if (isEmpty(userCity)) {
      dispatch(setUserCity({ city: "Bengaluru" }))
      Cookies.set("city", "Bengaluru", { expires: 7 })
      setShowCitySelectionModal(false)
    } else {
      setShowCitySelectionModal(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleConfirm}
      aria-labelledby="city-dialog-title"
      aria-describedby="city-dialog-description"
    >
      <DialogTitle id="update-dialog-title">
        <span>Please select your city</span>
        <br />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, mb: 2 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ color: theme.palette.primary.main }}
              id="demo-select-small-label"
            >
              City
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={userCity}
              label="City"
              sx={{
                height: 42,
                ".MuiOutlinedInput-notchedOutline": {
                  border: `2px solid ${theme.palette.primary.main}`,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: `2px solid ${theme.palette.primary.main}`,
                },
              }}
              onChange={handleChange}
            >
              <MenuItem value={"Bengaluru"}>Bengaluru</MenuItem>
              <MenuItem value={"Mumbai"}>Mumbai</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Stack>
          <Button onClick={handleConfirm} size="small">
            Confirm
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default CityModal

import PackageJSON from "../../package.json"

export const prepareHeadersHandler = (headers: any) => {
  const { version } = PackageJSON
  headers.set("web-app-version", version)
  try {
    const token = localStorage.getItem("access_token")
    const tokenExpiry = localStorage.getItem("token_expiration")
    if (token && tokenExpiry) {
      const expirationDate = new Date(Date.now() + parseInt(tokenExpiry) * 1000)
      if (expirationDate < new Date()) {
        // Token is expired, remove it from local storage
        localStorage.removeItem("user")
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        // Prompt user to re-authenticate
      } else {
        headers.set("Authorization", `Bearer ${token}`)
      }
    }
  } catch (e) {
    console.error(e)
  }
  return headers
}

import MobileNumber from "./MobileNumber"
import BookingPreferences from "./BookingPreferences"
import AddMembers from "./AddMembers"
import BookingSummary from "./BookingSummary"
import { StepperCard } from "./styles"
import { useSearchParams } from "react-router-dom"
import { URLParams } from "../../../constants/enums"
import { useEffect, useState } from "react"
import { getUserDataFromLocalStorage } from "../../../utils/storageUtils"
import { useDispatch } from "react-redux"

type StepperContentProps = {
  daypassPrice: number | undefined
  discountsPercentage: number | undefined
}

const StepperContent = ({
  daypassPrice,
  discountsPercentage,
}: StepperContentProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [authToken, setAuthToken] = useState("")
  const activeState = Number(searchParams.get(URLParams.ACTIVE_STATE)) || 0
  const dispatch = useDispatch()

  useEffect(() => {
    getUserDataFromLocalStorage(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    try {
      const accessToken = localStorage.getItem("access_token")
      setAuthToken(accessToken ?? "")
      const params = new URLSearchParams(window.location.search)
      if (!accessToken && activeState !== 0) {
        params.set(URLParams.ACTIVE_STATE, "0")
        setSearchParams(params.toString())
      } else if (accessToken && activeState === 0) {
        params.set(URLParams.ACTIVE_STATE, "1")
        setSearchParams(params.toString())
      }
      setSearchParams(params.toString())
    } catch (e) {
      console.error(e)
    }
  }, [activeState, setSearchParams])

  return (
    <StepperCard>
      {authToken ? (
        <>
          {activeState === 1 && <BookingPreferences />}
          {activeState === 2 && <AddMembers />}
          {activeState === 3 && (
            <BookingSummary
              daypassPrice={daypassPrice}
              discountsPercentage={discountsPercentage}
            />
          )}
        </>
      ) : (
        <>{activeState === 0 && <MobileNumber />}</>
      )}
    </StepperCard>
  )
}

export default StepperContent

import { useEffect, useRef, useState } from "react"
import {
  StepperCardFooter,
  StepperCardHeader,
  StepperCardBody,
  MobileNumberLabel,
  ConfirmButton,
  BackButton,
} from "./styles"
import MobileIcon from "../../../assets/MobileIcon.svg"
import OTPModal from "../../../components/OTPModal/OTPModal"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectBooking, setMobileNumber } from "../../../slices/bookingSlice"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CENTERS } from "../../../constants/path"
import { useGetOTPMutation, useUserLoginMutation } from "../../../apis/userApi"
import PhoneInput from "../../../components/PhoneInput/PhoneInput"
import { URLParams } from "../../../constants/enums"
import { getUserDataFromLocalStorage } from "../../../utils/storageUtils"

const TIMER = 30

const MobileNumber = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { mobileNumber } = useAppSelector(selectBooking)
  const [otpModalVisibility, setOtpModalVisibility] = useState(false)
  const [error, setError] = useState(false)
  const [count, setCount] = useState(TIMER)
  const [isCounting, setIsCounting] = useState(false)
  const intervalRef = useRef<number | ReturnType<typeof setTimeout> | null>(
    null,
  )
  const [getOTP] = useGetOTPMutation()
  const [userLogin, { isSuccess }] = useUserLoginMutation()

  useEffect(() => {
    getUserDataFromLocalStorage(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isSuccess) {
    setSearchParams((params) => {
      params.set(URLParams.ACTIVE_STATE, "1")
      return params
    })
  }

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    if (value.length <= 10) {
      dispatch(setMobileNumber({ value }))
    }
    if (value.length === 10) {
      setError(false)
    }
  }

  const generateOTPHandler = () => {
    if (mobileNumber.length === 10) {
      getOTP({ mobileNumber })
      setOtpModalVisibility(true)
      setIsCounting(true)
      setCount(TIMER) // set the initial count to TIMER seconds
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (isCounting) {
      intervalRef.current = setInterval(() => {
        setCount((prevCount) => prevCount - 1)
      }, 1000)
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [isCounting])

  useEffect(() => {
    if (count === 0) {
      setIsCounting(false)
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [count])

  return (
    <>
      <StepperCardHeader>
        <img src={MobileIcon} height="48px" alt="" />
      </StepperCardHeader>
      <StepperCardBody>
        <MobileNumberLabel>Please enter your mobile number</MobileNumberLabel>
        <PhoneInput
          value={mobileNumber}
          onChangeHandler={handleMobileNumberChange}
          error={error}
          handlePressEnter={generateOTPHandler}
        />
      </StepperCardBody>
      <StepperCardFooter>
        <BackButton
          variant="outlined"
          color="primary"
          onClick={() => navigate(CENTERS, { replace: true })}
          sx={{ mr: 1 }}
        >
          Back
        </BackButton>
        <ConfirmButton onClick={generateOTPHandler} variant="contained">
          Get OTP
        </ConfirmButton>
      </StepperCardFooter>
      {otpModalVisibility && (
        <OTPModal
          open={otpModalVisibility}
          onClose={setOtpModalVisibility}
          resendOTP={generateOTPHandler}
          onConfirm={userLogin}
          mobileNumber={mobileNumber}
          count={count}
          disabled={isCounting}
        />
      )}
    </>
  )
}

export default MobileNumber

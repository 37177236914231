import { Stack } from "@mui/material"
import Carousel from "react-material-ui-carousel"
import DistanceIcon from "../../assets/DistanceIcon.svg"
import PassIcon from "../../assets/PassIcon.svg"
import {
  Card,
  CenterHeading,
  CenterImage,
  Distance,
  CardHeader,
  CardBody,
  PassWrapper,
  PassText,
  PassPrice,
  DiscountPercentage,
  CenterImagePlaceholder,
} from "./styles"
import { createSearchParams, useNavigate } from "react-router-dom"
import { BOOKING } from "../../constants/path"
import { computeRoute } from "../../utils/compute-route"
import { Center } from "../../models/centers.model"
import { DaypassType, URLParams } from "../../constants/enums"
import { calculateDaypassValues } from "../../utils/calculateDaypassValues"
import { useAppSelector } from "../../app/hooks"
import { selectUser } from "../../slices/userSlice"
import { staticRelativeImageHandler } from "../../utils/imageHandler"

type CenterCardProps = {
  center: Center
}

const CenterCard = ({ center }: CenterCardProps) => {
  const navigate = useNavigate()
  const { userData } = useAppSelector(selectUser)
  const { totalAmountWithoutTax } = calculateDaypassValues(
    center.daypassPrice,
    center.discountsPercentage,
    1,
    18,
    true,
  )

  const bookingHandler = (passType: string) => {
    navigate({
      pathname: computeRoute(BOOKING, { centerId: center.centerId }),
      search: createSearchParams({
        [URLParams.PASS_TYPE]: passType,
        [URLParams.ACTIVE_STATE]: userData.accessToken === "" ? "0" : "1",
      }).toString(),
    })
  }

  return (
    <Card id={center.centerId}>
      <CardHeader>
        <CenterHeading>{center?.centerName}</CenterHeading>
        <Distance
          onClick={() => {
            if (center?.googleMapURL) {
              window.open(center?.googleMapURL)
            }
          }}
        >
          <img src={DistanceIcon} alt="distance-icon" height="18px" />
        </Distance>
      </CardHeader>
      <CardBody>
        <Carousel animation="slide" indicators={false} autoPlay={false}>
          {center.centerImages && center.centerImages?.length !== 0 ? (
            center.centerImages.map((src, index) => (
              <Stack key={index} gap={1}>
                <CenterImage
                  src={staticRelativeImageHandler(src)}
                  alt={`Uploaded ${index}`}
                />
              </Stack>
            ))
          ) : (
            <CenterImagePlaceholder />
          )}
        </Carousel>
        <Stack direction="row" justifyContent="space-between">
          <PassWrapper
            passType={DaypassType.DAYPASS}
            onClick={() => bookingHandler(DaypassType.DAYPASS)}
          >
            <Stack>
              <PassText passType={DaypassType.DAYPASS}>Day Pass</PassText>
              <PassPrice passType={DaypassType.DAYPASS}>
                ₹{center.daypassPrice}
                <span>/Day</span>
              </PassPrice>
            </Stack>
            <img src={PassIcon} alt="pass-icon" />
          </PassWrapper>
          <PassWrapper
            passType={DaypassType.BULKDAYPASS}
            onClick={() => bookingHandler(DaypassType.BULKDAYPASS)}
          >
            <Stack>
              <PassText passType={DaypassType.BULKDAYPASS}>
                Bulk Day Pass
              </PassText>
              <PassPrice passType={DaypassType.BULKDAYPASS}>
                <s>₹{center.daypassPrice * 10}</s> ₹{totalAmountWithoutTax}
                <span>/10 Days</span>
              </PassPrice>
            </Stack>
            <Stack direction="row" alignItems="center">
              <img src={PassIcon} alt="pass-icon" />
              <DiscountPercentage>
                <span>{center.discountsPercentage}% Discount</span>
              </DiscountPercentage>
            </Stack>
          </PassWrapper>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default CenterCard

import { useEffect, useState } from "react"
import { Dialog, FormControl, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { CloseIconButton } from "../OTPModal/styles"
import {
  FormContainer,
  GstModalContainer,
  RedText,
  StyledFormHelperText,
  TitleContainer,
  TitleText,
} from "./style"
import { CustomOutlinedInput } from "../PhoneInput/styles"
import { PriceBeakupSubText } from "../../containers/Booking/StepperContent/styles"
import { CustomButton } from "../../containers/Booking/styles"
import {
  COMPANY_ADDRESS_REQUIRED,
  COMPANY_NAME_REQUIRED,
  INVALID_GST,
  INVALID_PINCODE,
} from "../../constants/validations"
import { useUpdateUserGstInfoMutation } from "../../apis/centersApi"
import { useAppDispatch } from "../../app/hooks"
import { showToast } from "../../slices/appSlice"
import { UserState } from "../../containers/Booking/StepperContent/BookingSummary"
import { GST_REGEX } from "../../utils/regex"

interface IModalProps {
  open: boolean
  handleToggleGstModal: () => void
  userData: UserState
}

const GstModal = ({ open, handleToggleGstModal, userData }: IModalProps) => {
  const [validationError, setValidationError] = useState<string[] | null>(null)
  const [gstCompanyDetails, setGstCompanyDetails] = useState({
    gstin: "",
    companyAddress: "",
    companyName: "",
    pincode: "",
  })
  const dispatch = useAppDispatch()

  const [updateUserGstInfo, { isLoading }] = useUpdateUserGstInfoMutation()
  useEffect(() => {
    setValidationError([])
    if (userData?.gstDetails) {
      setGstCompanyDetails({
        gstin: userData?.gstDetails?.gstin ?? "",
        companyAddress: userData?.gstDetails?.companyAddress ?? "",
        companyName: userData?.gstDetails?.companyName ?? "",
        pincode: userData?.gstDetails?.pincode ?? "",
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const validateCompanyDetails = (
    gstin = "",
    companyName = "",
    companyAddress = "",
    companyPin = "",
  ) => {
    const errors = []

    if (!gstin || gstin?.length !== 15 || !GST_REGEX.test(gstin)) {
      errors.push(INVALID_GST)
      setValidationError(errors)
      return
    }

    if (!companyName || companyName?.trim() === "") {
      errors.push(COMPANY_NAME_REQUIRED)
      setValidationError(errors)
      return
    }

    if (!companyAddress || companyAddress?.trim() === "") {
      errors.push(COMPANY_ADDRESS_REQUIRED)
      setValidationError(errors)
      return
    }

    if (!companyPin || companyPin?.length !== 6 || !/^\d+$/.test(companyPin)) {
      errors.push(INVALID_PINCODE)
      setValidationError(errors)
      return
    }

    setValidationError([])

    return true
  }

  const handleInputChange = (
    field: keyof typeof gstCompanyDetails,
    value: string,
  ) => {
    setValidationError([])
    setGstCompanyDetails({
      ...gstCompanyDetails,
      [field]: value,
    })
  }

  const handleSubmit = async () => {
    const { gstin, companyName, companyAddress, pincode } = gstCompanyDetails
    const isValid = validateCompanyDetails(
      gstin,
      companyName,
      companyAddress,
      pincode,
    )
    if (isValid) {
      try {
        const gstInfo = {
          gstin,
          companyName,
          companyAddress,
          pincode,
        }
        try {
          await updateUserGstInfo(gstInfo)
        } catch (error) {
          console.error("Error Updating GST Info:", error)
          dispatch(
            showToast({
              toastType: "error",
              msg: "Something went wrong",
            }),
          )
        }
      } catch (error) {
        console.error(error)
      }

      handleToggleGstModal()
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="gst-dialog-title"
      aria-describedby="gst-dialog-description"
      onClose={handleToggleGstModal}
      fullWidth={true}
      PaperProps={{ style: { borderRadius: "12px" } }}
    >
      <GstModalContainer>
        <CloseIconButton onClick={handleToggleGstModal}>
          <CloseIcon />
        </CloseIconButton>
        <TitleContainer>
          <TitleText>Add GST Details</TitleText>
        </TitleContainer>
        <FormContainer>
          <FormControl variant="outlined" fullWidth>
            <Stack
              direction="column"
              alignItems="baseline"
              gap={1}
              sx={{ marginBottom: 1 }}
              width="100%"
            >
              <PriceBeakupSubText>
                GSTIN <RedText>*</RedText>
              </PriceBeakupSubText>
              <CustomOutlinedInput
                sx={{ height: "44px" }}
                type="text"
                fullWidth
                required
                name="gstin"
                value={gstCompanyDetails.gstin}
                onBlur={(e) => {
                  validateCompanyDetails(
                    e.target.value,
                    gstCompanyDetails.companyName,
                    gstCompanyDetails.companyAddress,
                    gstCompanyDetails.pincode,
                  )
                }}
                onChange={(e) => handleInputChange("gstin", e.target.value)}
                error={validationError?.includes(INVALID_GST)}
              />
              {validationError?.includes(INVALID_GST) && (
                <StyledFormHelperText error>{INVALID_GST}</StyledFormHelperText>
              )}
            </Stack>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Stack
              direction="column"
              alignItems="baseline"
              gap={1}
              sx={{ marginBottom: 1 }}
              width="100%"
            >
              <PriceBeakupSubText>
                Company Name <RedText>*</RedText>
              </PriceBeakupSubText>
              <CustomOutlinedInput
                sx={{ height: "44px" }}
                type="text"
                fullWidth
                required
                value={gstCompanyDetails.companyName}
                name="companyName"
                onBlur={(e) => {
                  validateCompanyDetails(
                    gstCompanyDetails.gstin,
                    e.target.value,
                    gstCompanyDetails.companyAddress,
                    gstCompanyDetails.pincode,
                  )
                }}
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
                error={validationError?.includes(COMPANY_NAME_REQUIRED)}
              />
              {validationError?.includes(COMPANY_NAME_REQUIRED) && (
                <StyledFormHelperText error>
                  {COMPANY_NAME_REQUIRED}
                </StyledFormHelperText>
              )}
            </Stack>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Stack
              direction="column"
              alignItems="baseline"
              gap={1}
              sx={{ marginBottom: 1 }}
              width="100%"
            >
              <PriceBeakupSubText>
                Company Address <RedText>*</RedText>
              </PriceBeakupSubText>
              <CustomOutlinedInput
                multiline
                minRows={2}
                maxRows={4}
                onBlur={(e) => {
                  validateCompanyDetails(
                    gstCompanyDetails.gstin,
                    gstCompanyDetails.companyName,
                    e.target.value,
                    gstCompanyDetails.pincode,
                  )
                }}
                value={gstCompanyDetails.companyAddress}
                onChange={(e) =>
                  handleInputChange("companyAddress", e.target.value)
                }
                type="text"
                fullWidth
                required
                name="companyAddress"
                error={validationError?.includes(COMPANY_ADDRESS_REQUIRED)}
              />
              {validationError?.includes(COMPANY_ADDRESS_REQUIRED) && (
                <StyledFormHelperText error>
                  {COMPANY_ADDRESS_REQUIRED}
                </StyledFormHelperText>
              )}
            </Stack>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <Stack
              direction="column"
              alignItems="baseline"
              gap={1}
              sx={{ marginBottom: 1 }}
              width="100%"
            >
              <PriceBeakupSubText>
                Pincode <RedText>*</RedText>
              </PriceBeakupSubText>
              <CustomOutlinedInput
                sx={{ height: "44px" }}
                type="number"
                fullWidth
                required
                name="pincode"
                value={gstCompanyDetails.pincode}
                onBlur={(e) => {
                  validateCompanyDetails(
                    gstCompanyDetails.gstin,
                    gstCompanyDetails.companyName,
                    gstCompanyDetails.companyAddress,
                    e.target.value,
                  )
                }}
                onChange={(e) => handleInputChange("pincode", e.target.value)}
                error={validationError?.includes(INVALID_PINCODE)}
              />
              {validationError?.includes(INVALID_PINCODE) && (
                <StyledFormHelperText error>
                  {INVALID_PINCODE}
                </StyledFormHelperText>
              )}
            </Stack>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <CustomButton
              variant="contained"
              sx={{ minWidth: "100%", marginTop: 2 }}
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Add
            </CustomButton>
          </FormControl>
        </FormContainer>
      </GstModalContainer>
    </Dialog>
  )
}

export default GstModal

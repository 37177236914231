import { User } from "../models/users.model"
import { resetMobileNumber, setMobileNumber } from "../slices/bookingSlice"
import { resetUserData, setUserData } from "../slices/userSlice"
import { Dispatch } from "redux"

export const getUserDataFromLocalStorage = (dispatch: Dispatch) => {
  try {
    const accessToken = localStorage.getItem("access_token")
    const refreshToken = localStorage.getItem("refresh_token")
    const storedUser = localStorage.getItem("user")
    const user = storedUser !== null ? JSON.parse(storedUser) : {}
    const parsedUser = {
      access_token: accessToken,
      refresh_token: refreshToken,
      user: {
        ...user,
      },
    }
    if (accessToken) {
      const updatedUser = {
        ...new User(parsedUser),
      }
      dispatch(setUserData({ userData: updatedUser }))
      dispatch(setMobileNumber({ value: user.mobile }))
    }
  } catch (error) {
    console.error(error)
  }
}

export const setUserDataToLocalStorage = (
  dispatch: Dispatch,
  user: any,
  authToken: string,
  refreshToken: string,
  updatedUser: any,
  tokenExpiry: number,
) => {
  try {
    localStorage.setItem("user", JSON.stringify(user))
    localStorage.setItem("token_expiration", tokenExpiry.toString())
    localStorage.setItem("access_token", authToken)
    localStorage.setItem("refresh_token", refreshToken)
    dispatch(setUserData({ userData: updatedUser }))
  } catch (e) {
    console.error(e)
  }
}

export const flushUserDataFromLocalStorage = (dispatch: Dispatch) => {
  try {
    localStorage.removeItem("user")
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("token_expiration")
    dispatch(resetUserData())
    dispatch(resetMobileNumber())
  } catch (e) {
    console.error(e)
  }
}
